<template>
    <div>
      <ServerError v-if="ServerError" />
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        is-full-screen
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout v-if="ServerError" wrap justify-center>
        <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
          <ServerError />
        </v-flex>
      </v-layout>
      <v-layout v-else wrap justify-center>
        <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
          <v-card class="pa-6">
            <v-card-title class="elevation-1" style="font-family: poppinsextrabold;">
              Sub-Category({{ user.name }})
              <v-spacer></v-spacer>
            </v-card-title>
            <div class="pt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" v-if="user.category">
                      <span class="item_title">Category</span>
                      <hr />
                      <br />
                      <span>
                        <v-chip class="item_value ma-2">{{
                          user.category.name
                        }}</v-chip></span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="5">
                      <span class="item_title">Sub-Category</span> <hr /><br>
                      <v-chip class="item_value">{{ user.name }}</v-chip>
  
                    </v-col>
                  </v-row>
                 
                </v-col> -->
                
               
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12">
                      <span class="item_title">Description</span>
                      <hr />
                      <br />
                      <v-row>
                        <v-col cols="12" md="12">
                          <span class="item_value" v-html="user.description"></span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
           
              </v-row>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    props: ["subid"],
    data() {
      return {
        showsnackbar: false,
        ServerError: false,
        msg: null,
        appLoading: false,
        dialog: false,
        itemid: "",
        category: [],
        categoryView: "",
        itemediting: [],
        user: [],
        data: [],
        name: [],
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 8 || "Min 8 characters",
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
    
      getData() {
        this.appLoading = true;
        axios({
          url: "/course/sub-catogory/view",

          method: "GET",
          params: {
            id: this.$route.query.id,
          },
          headers: {
           token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.user = response.data.data;
            // this.name = response.data.data.name;
            // this.categoryArray = response.data.data.category;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
     
    },
  };
  </script>
  <style scoped>
  .item_title {
    font-weight: bold;
    font-family: poppinssemibold;
  }
  </style>
  